import { useEffect, useState } from "react";
import CustomButton from "../../../../../atoms/CustomButton";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "./index.module.scss";
import moment from "moment/moment";
import { getData, patchData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { breakStringInMiddle } from "../../../../../../helpers/capatalizeLetters";
import { CustomInput, CustomDropDown } from "../../../../../atoms/CustomInput";

const ReviewView = ({
  paymentInfoData,
  setAssignModalState,
  getPaymentStatus,
}) => {
  const [cookies] = useCookies(["t"]);
  const [fundingDueData, setFundingDueData] = useState([]);
  const [fundingDetails, setFundingDetails] = useState({
    client_id: "",
    supplier_id: "",
    trans_type: "",
  });
  const [manualAdv, setManualAdv] = useState(false);
  const [autoApplyInterest, setAutoApplyInterest] = useState(false);
  const [autoApplyCost, setAutoApplyCost] = useState(false);
  const [autoApplyFull, setAutoApplyFull] = useState(false);
  const [reservedAmount, setReservedAmount] = useState();
  const createDocRecords = (num) => {
    return Array.from({ length: num }, () => ({
      paymentApp: "",
      postAppStatus: "",
      paymentType: "",
    }));
  };

  const [manualAdvData, setManualAdvData] = useState(createDocRecords(0));

  const getFundingDues = async () => {
    try {
      const res = await getData({
        endpoint: "funding/geFundingClientIdAndSupplierId",
        params: {
          client_id: fundingDetails?.client_id,
          supplier_id: fundingDetails?.supplier_id,
          trans_type: fundingDetails?.trans_type,
        },
        token: cookies.t,
      });
      setFundingDueData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (fundingDetails?.client_id) {
      getFundingDues();
    }
  }, [fundingDetails]);

  console.log("fundingDueData", fundingDueData);
  console.log("mannualAdvData", manualAdvData);

  useEffect(() => {
    if (paymentInfoData?.client_id) {
      if (
        paymentInfoData?.obligor_id &&
        paymentInfoData?.transaction_type === "Obligor"
      ) {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      } else if (
        paymentInfoData?.obligor_id &&
        paymentInfoData?.transaction_type === "Supplier"
      ) {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      } else if (paymentInfoData?.transaction_type === "Supplier Credit-1") {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: "Supplier",
        }));
      } else if (paymentInfoData?.transaction_type === "Client") {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      }
    }
  }, [paymentInfoData]);

  const updateFundingDueData = (fundingDueData, paymentInfoData) => {
    let remainingAmount =
      parseFloat(paymentInfoData?.amount) +
      parseFloat(fundingDueData?.[0]?.paymentHistory?.[0]?.paymentAmount);

    const updatedData = fundingDueData.map((item, index) => {
      const totalValue =
        parseFloat(item?.cost_value) + parseFloat(item?.face_value);

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;

        return {
          ...item,
          paymentApp: totalValue.toFixed(2),
          postAppStatus: "Close",
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;

        return {
          ...item,
          paymentApp: currentAmount.toFixed(2),
          postAppStatus: "open",
        };
      } else {
        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };

  const updateManualAdvData = () => {
    const { updatedData, remainingAmount } = updateFundingDueData(
      fundingDueData,
      paymentInfoData,
    );
    setReservedAmount(remainingAmount);
    setManualAdvData(updatedData);
  };

  const handleManualAdvanced = () => {
    setManualAdv((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateManualAdvData();
  };

  const handleConfirmAndApply = async () => {
    if (manualAdv) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (reservedAmount == parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (reservedAmount > parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Reserves",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    } else if (autoApplyInterest) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (reservedAmount == parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (reservedAmount > parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Reserves",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    } else if (autoApplyFull) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (reservedAmount == parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (reservedAmount > parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Reserves",
                },
              });
              if (res) {
                setAssignModalState(() => ({
                  isModalOpen: false,
                  modalId: "",
                  modalTitle: "",
                  data: "",
                }));
                getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const updateAutoApplyInterest = (fundingDueData, paymentInfoData) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount);

    const updatedData = fundingDueData.map((item, index) => {
      const totalValue = parseFloat(item?.face_value);

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;

        return {
          ...item,
          paymentApp: totalValue.toFixed(2),
          postAppStatus: "open",
          paymentType: "Interest",
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;

        return {
          ...item,
          paymentApp: currentAmount.toFixed(2),
          postAppStatus: "open",
          paymentType: "Interest",
        };
      } else {
        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
          paymentType: "Interest",
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };
  const updateAutoInterest = async () => {
    const { updatedData, remainingAmount } = updateAutoApplyInterest(
      fundingDueData,
      paymentInfoData,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  const handleApplyInterest = () => {
    setAutoApplyInterest((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoInterest();
  };

  const updateAutoApplyCost = (fundingDueData, paymentInfoData) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount);

    const updatedData = fundingDueData.map((item, index) => {
      const totalValue = parseFloat(item?.cost_value);

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;

        return {
          ...item,
          paymentApp: totalValue.toFixed(2),
          postAppStatus: "open",
          paymentType: "Principle",
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;

        return {
          ...item,
          paymentApp: currentAmount.toFixed(2),
          postAppStatus: "open",
          paymentType: "Principle",
        };
      } else {
        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
          paymentType: "Principle",
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };
  const updateAutoCost = async () => {
    const { updatedData, remainingAmount } = updateAutoApplyCost(
      fundingDueData,
      paymentInfoData,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  const handleAutoApplyCost = () => {
    setAutoApplyCost((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoCost();
  };

  const updateAutoApplyFull = (fundingDueData, paymentInfoData) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount);

    const updatedData = fundingDueData.map((item, index) => {
      const totalValue =
        parseFloat(item?.cost_value) + parseFloat(item?.face_value);

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;

        return {
          ...item,
          paymentApp: totalValue.toFixed(2),
          postAppStatus: "Close",
          paymentType: "PIK-Interest",
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;

        return {
          ...item,
          paymentApp: currentAmount.toFixed(2),
          postAppStatus: "open",
          paymentType: "PIK-Interest",
        };
      } else {
        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
          paymentType: "Interest",
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };

  const updateAutoFull = async () => {
    const { updatedData, remainingAmount } = updateAutoApplyFull(
      fundingDueData,
      paymentInfoData,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  const handleAutoApplyFull = () => {
    setAutoApplyFull((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoFull();
  };

  return (
    <>
      <div className={style.info_history_wrap}>
        <div className={style.tableWrap}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Payment Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Obligor name</td>
                <td>
                  {paymentInfoData?.obligor_name
                    ? paymentInfoData?.obligor_name
                    : paymentInfoData?.client_name}
                </td>
              </tr>
              <tr>
                <td>Payment Date</td>
                <td>{moment(paymentInfoData?.date).format("YYYY-MM-DD")}</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{paymentInfoData?.amount}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>{paymentInfoData?.currency}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{paymentInfoData?.status}</td>
              </tr>
              <tr>
                <td>Participant ID</td>
                <td>--</td>
              </tr>
              <tr>
                <td>Reserve Amount</td>
                <td>{reservedAmount ? reservedAmount : 0}</td>
              </tr>
              <tr>
                <td>Assignment Type</td>
                <td>AssignSingular</td>
              </tr>
              <tr>
                <td>Calculation Date</td>
                <td>
                  <div style={{ position: "relative" }}>
                    <DateSelector
                      placeholder="Calculation Date"
                      selectedDate={paymentInfoData?.date}
                      disabled
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.history_wrap}>
          <div className="mt-4">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray">
                  <th colSpan="8" className="border p-2 text-left">
                    Obligor Assets Due
                  </th>
                  <th
                    colSpan={
                      manualAdv ||
                      autoApplyInterest ||
                      autoApplyFull ||
                      autoApplyCost
                        ? "6"
                        : "5"
                    }
                    className="border p-2 text-left"
                  >
                    As of Calculation Date
                  </th>
                </tr>
                <tr className="bg-gray">
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Obligor
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Due Date
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Client Ref
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Fund
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Status
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Currency
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Cost
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Face value
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Priciple OutStanding
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Interest Accured
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Amount to Close
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Payment Application
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Post Application Status
                  </th>
                  {(manualAdv ||
                    autoApplyInterest ||
                    autoApplyFull ||
                    autoApplyCost) && (
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Repayment Type
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {fundingDueData?.map((row, index) => {
                  const isPaymentOpen =
                    row?.paymentHistory?.[0]?.paymentState === "open";
                  const isEditable = manualAdv && isPaymentOpen;
                  return (
                    <>
                      <tr className="" key={index}>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row.obligor_name
                            ? row.obligor_name
                            : row?.client_name}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {moment(row?.due_date).format("YYYY-MM-DD")}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.transaction_type === "Obligor" && (
                            <>{breakStringInMiddle(row?.invoice_number)}</>
                          )}
                          {(row?.transaction_type === "Supplier Credit-1" ||
                            row?.transaction_type === "Supplier Credit" ||
                            row?.transaction_type === "Supplier") && (
                            <>
                              <>{breakStringInMiddle(row?.invoice_number)}</>
                            </>
                          )}
                          {(row?.transaction_type === "Client" ||
                            row?.transaction_type === "Note") && (
                            <>{breakStringInMiddle(row?.referance)}</>
                          )}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.funder}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.status}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.currency}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.cost_value}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {parseFloat(row?.cost_value) +
                            parseFloat(row?.face_value)}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.cost_value}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.face_value}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {parseFloat(row?.cost_value) +
                            parseFloat(row?.face_value)}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost ? (
                            <CustomInput
                              onchange={(data) => {
                                const tempData = [...manualAdvData];
                                tempData[index].paymentApp = data;
                                setManualAdvData(tempData);
                              }}
                              value={manualAdvData[index].paymentApp}
                              inputType="text"
                            />
                          ) : row?.paymentHistory?.[0]?.paymentAmount ? (
                            row?.paymentHistory?.[0]?.paymentAmount
                          ) : (
                            0
                          )}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost ? (
                            <CustomDropDown
                              dropDownItem={[
                                { label: "Open", value: "Open" },
                                { label: "Close", value: "Close" },
                              ]}
                              onSelect={(value) => {
                                const tempData = [...manualAdvData];
                                tempData[index].postAppStatus = value;
                                setManualAdvData(tempData);
                              }}
                              selectedValue={
                                manualAdvData[index]?.postAppStatus
                              }
                            />
                          ) : row?.paymentHistory?.[0]?.paymentState ? (
                            row?.paymentHistory?.[0]?.paymentState
                          ) : (
                            "open"
                          )}
                        </td>
                        {(manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost) && (
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            <CustomDropDown
                              dropDownItem={[
                                { label: "Interest", value: "Interest" },
                                { label: "Principle", value: "Principle" },
                                {
                                  label: "PIK-Interest",
                                  value: "PIK-Interest",
                                },
                              ]}
                              onSelect={(value) => {
                                const tempData = [...manualAdvData];
                                tempData[index].paymentType = value;
                                setManualAdvData(tempData);
                              }}
                              selectedValue={manualAdvData[index]?.paymentType}
                            />
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <CustomButton
          text="Confirm and Apply"
          size="btn-lg"
          type="btn-primary"
          handleClick={() => {
            handleConfirmAndApply();
          }}
        />
      </div>
      <div className={style.CustomButtonWrap}>
        <div className={style.autoWrap}>
          <CustomButton
            text="Auto Apply (Full)"
            size="btn-lg"
            type="btn-primary"
            handleClick={() => {
              handleAutoApplyFull();
            }}
          />
          <CustomButton
            text="Auto Apply (Cost)"
            size="btn-lg"
            type="btn-primary"
            handleClick={() => {
              handleAutoApplyCost();
            }}
          />
          <CustomButton
            text="Auto Apply (Interest)"
            size="btn-lg"
            type="btn-primary"
            handleClick={() => {
              handleApplyInterest();
            }}
          />
          <CustomButton
            text="Manual"
            size="btn-lg"
            type="btn-primary"
            handleClick={() => {
              handleManualAdvanced();
            }}
          />
        </div>
        <div>
          <CustomButton
            text="Cancel"
            size="btn-lg"
            type="btn-primary"
            handleClick={() => {
              setManualAdv(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ReviewView;
